<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('externalLrcpn.challan_no') }} : {{challan_no}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
            </b-button>
            <a href="javascript:void(0)" @click="hasHistory() ? $router.go(-1) : ''" aria-current="page" class="btn btn_add_new mr-2"><i class="ri-arrow-left-line"></i>{{ $t('globalTrans.back') }}</a>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col>
                  <list-report-head :base-url="lrcpanBaseUrl" :uri="'/configuration/report-heading/detail'" :org-id="1">
                    {{ $t('globalTrans.application_id') }} : {{$route.query.application_id}}<br/>
                    {{ $t('externalLrcpn.challan_no') }} : {{challan_no}}
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table v-if="!onlinePayment" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(date_of_bi_5529)="data">
                      {{ data.item.created_at|dateFormat }}
                    </template>
                    <template v-slot:cell(amount)="data">
                      {{ $n(data.item.amount) }}
                    </template>
                    <template v-slot:cell(attachment)="data">
                       <a target="_blank" class="btn btn-sm btn-success" v-if="data.item.attachment" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + data.item.attachment"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button title="Active/Inactive" variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <div class="panel-body text-center mt-3" v-else>
                    <h6 class="text-danger">{{ $t('li_step.this_is_online_payment') }}</h6>
                  </div>
                  <!-- <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    /> -->
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
      <!-- <pre>{{ datas }}</pre> -->
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { chalanSearchs } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ExportPdf from './export_pdf_details'
import ListReportHead from '@/components/custom/ListReportHeadLrcpn.vue'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  data () {
    return {
      search: {
        service_id: this.$route.query.service_id,
        application_id: this.$route.query.application_id
      },
      datas: [],
      onlinePayment: false,
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl,
      challan_no: '',
      districtList: [],
      upazilaList: []
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('globalTrans.market_name') + ' ' + this.$t('globalTrans.entry') : this.$t('globalTrans.market_name') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('globalTrans.application_id'), class: 'text-center' },
        { label: this.$t('globalTrans.applicants_name'), class: 'text-center' },
        { label: this.$t('globalTrans.company_name'), class: 'text-center' },
        { label: this.$t('externalLrcpn.branch_name'), class: 'text-center' },
        { label: this.$t('globalTrans.date'), class: 'text-left' },
        { label: this.$t('globalTrans.amount'), class: 'text-left' },
        { label: this.$t('globalTrans.attachment'), class: 'text-left' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_of_ap_____7351' },
          { key: 'company_na___9403' },
          { key: 'branch_name' },
          { key: 'date_of_bi_5529' },
          { key: 'amount' },
          { key: 'attachment' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_of_ap_____8058' },
          { key: 'company_na__1531' },
          { key: 'branch_name' },
          { key: 'date_of_bi_5529' },
          { key: 'attachment' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.loadData()
  },
  methods: {
    hasHistory () { return window.history.length > 2 },
    pdfExport () {
      const reportTitle = this.$t('externalLrcpn.challan_no') + ':' + this.challan_no
      ExportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, '/configuration/report-heading/detail', 1, reportTitle, this)
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      return upazilaList.filter(upazila => upazila.district_id === districtId)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      return districtList.filter(district => district.division_id === divisionId)
    },
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(licenseRegistrationServiceBaseUrl, chalanSearchs, params).then(response => {
        if (response.success) {
          this.datas = response.data
          // this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
          this.challan_no = response.challan_no
        } else {
          if (response.onlinePayment) {
            this.onlinePayment = true
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getOrgName (orgId) {
      const tmpOrg = this.organizationList.find(org => org.value === orgId)
      return this.$i18n.locale === 'en' ? tmpOrg !== undefined ? tmpOrg.text_en : '' : tmpOrg !== undefined ? tmpOrg.text_bn : ''
    }
  }
}
</script>
