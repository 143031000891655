import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-lic-o'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const appliantId = vm.$t('globalTrans.application_id') + ':' + vm.$route.query.application_id
      const pdfContent = [{
        columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: appliantId, style: 'hh', alignment: 'center' },
            { text: reportTitle, style: 'hhs', alignment: 'center' }
        ]
      let allRows = []
      allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.application_id'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.applicants_name'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.company_name'), style: 'th', alignment: 'center' },
          { text: vm.$t('externalLrcpn.branch_name'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.amount'), style: 'th', alignment: 'center' }
        ]
      ]
      // table body
      if (vm.datas.length) {
        vm.datas.filter((item, index) => {
          if (item.date_of_bi_5529) {
            item.date_of_bi_5529 = dateFormat(item.created_at)
          }
          const rowItem = [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: item.application_id, style: 'td', alignment: 'center' },
            { text: i18n.locale === 'en' ? item.name_of_ap_____8058 : item.name_of_ap_____7351, style: 'td', alignment: 'center' },
            { text: i18n.locale === 'en' ? item.company_na__1531 : item.company_na___9403, style: 'td', alignment: 'center' },
            { text: item.branch_name, style: 'td', alignment: 'center' },
            { text: item.date_of_bi_5529, style: 'td', alignment: 'center' },
            { text: vm.$n(item.amount), style: 'td', alignment: 'center' }
          ]
          allRows.push(rowItem)
        })
      } else {
        const rowItem = [
          { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: 7 },
          { },
          { },
          { },
          { },
          { },
          { }
        ]
        allRows.push(rowItem)
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['7%', '10%', '20%', '20%', '17%', '13%', '15%'],
          body: allRows
        }
      })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 10,
            bold: true,
            margin: [1, 1, 1, 1]
          },
          td: {
            fontSize: 9,
            margin: [1, 1, 1, 1]
          },
          header: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 5, 25, 0]
          },
          hhs: {
            fontSize: 13,
            bold: true,
            margin: [10, 5, 25, 5]
          },
          krishi: {
            margin: [0, 10, 0, 15],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
